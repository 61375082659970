export const environment = {
    DOMAIN:'app.taskopad.com',
    production: false,
    API_URL: 'https://bff.taskopad.com/',
    url: 'https://bff.taskopad.com/',
    userUrl: 'https://user.taskopad.com/',
    subscriptionUrl: 'https://sub.taskopad.com/',
    discussionURL: 'https://discussion.taskopad.com/',
    socketUrl: 'https://discussion.taskopad.com/',
    RECAPTCHA_V3_SITE_KEY: '6LcZRDMnAAAAAEcsehsK5KAFft-1qDBXD_wdjlI_',
    RECAPTCHA_V2_SITE_KEY: '6LcTbeAcAAAAAGPYWbevOiIDQuvOjKp7HUPVxHSq',
    RAZOR_PAY_KEY: 'rzp_live_LG9AQAvK4zPwgP',
    buildVersion: '2.0.1',
    firebase: {
        apiKey: 'AIzaSyBI2YUMvp0nWu_DUA54dtMO66I_uoZUr7U',
        authDomain: 'taskopad-prod.firebaseapp.com',
        projectId: 'taskopad-prod',
        storageBucket: 'taskopad-prod.appspot.com',
        messagingSenderId: '901319585967',
        appId: '1:901319585967:web:23819bfe6767c8a60c645a',
        measurementId: 'G-TJN5195QRQ',
    },
};
